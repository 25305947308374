import React, { useEffect } from "react";
import { Router, WindowLocation } from "@reach/router";
import NotFound from "contents/NotFound";
import NewsLayout from "layouts/NewsLayout";
import ServiceArticle from "components/News/ServiceArticle";
import ServiceYahooArticle from "components/News/ServiceYahooArticle";
import Route from "components/Routes/Route";

type Props = {
  location: WindowLocation;
};

export default function Page({ location }: Props) {
  return (
    <NewsLayout backButton={true}>
      <Router basepath="/article">
        <Route component={NotFound} path="/" />
        <Route component={ServiceArticle} path="/news/:id" />
        <Route
          component={ServiceYahooArticle}
          path="/keyword/:id"
          className="whitespace-pre-wrap"
        />
      </Router>
    </NewsLayout>
  );
}
